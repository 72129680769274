<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="$router.push({ name: 'ministry' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>

      <a-row class="post-search" type="flex">
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>

        <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button>

        <a-button
          type="primary"
          @click="$router.push({ name: 'staffs-create' })"
        >
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>

      <a-row type="flex" style="width: 100%; justify-content: space-between">
        <h1>{{ title[$i18n.locale] }}</h1>
        <a-button
          :disabled="cardsIsEmpty"
          type="danger"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          <!--{{ $t("Delete") }}-->
        </a-button>
      </a-row>
    </a-row>

    <!-- <a-row>
        <a-col :span="6" class="sm-mb">
          <a-input
            v-model="search"
            placeholder="Поиск сотрудника..."
            @input="SearchInput"
          />
        </a-col>
      </a-row> -->

    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :loading="loading"
      :data-source="tableProps.results"
      :pagination="false"
      :scroll="{ x: 3000, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'staffs-detail',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="order" slot-scope="item">
        <a-input-number
          v-model="item.order"
          style="width: 60px; margin-right: 10px"
          :min="1"
          @pressEnter="updatePosition(item, $event)"
        />
      </template>

      <template slot="photo" slot-scope="item">
        <!--<spinner v-if="loading" />-->
        <div v-if="!item.thumb">no photo</div>
        <div v-else>
          <img
            style="width: 80px; height: 80px; object-fit: cover"
            alt="profile_img"
            :src="item.thumb"
          />
        </div>
      </template>

      <template slot="full_name" slot-scope="item">
        <div
          class="td-post-item"
          @click="
            $router.push({
              name: 'staffs-detail',
              params: { id: item.id }
            })
          "
        >
          <b style="cursor: pointer">{{ item.full_name }}</b>
        </div>
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="position" slot-scope="item">
        {{ item.position || "-" }}
      </template>

      <template slot="nation" slot-scope="item">
        {{ item.nation || "-" }}
      </template>

      <template slot="phone_number" slot-scope="item">
        {{ item.phone_number || "-" }}
      </template>

      <template slot="inner_number" slot-scope="item">
        {{ item.inner_number || "-" }}
      </template>

      <template slot="birth_date" slot-scope="item">
        <span v-if="item.birth_date">
          {{ moment(item.updated_at).format("LL") }}
        </span>
        <span v-else>-</span>
      </template>

      <template slot="author" slot-scope="item">
        {{ item.author || "-" }}
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          class="edit-btn"
          type="primary"
          @click="
            $router.push(
              {
                name: 'staffs-detail',
                params: { id: item.id }
              },
              () => null
            )
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon class="action-btns" type="edit" />
        </a-button>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :current="page"
        :default-page-size="perPage"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: "80px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Order"),
          key: "order",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "order" }
        },
        {
          title: this.$t("TablePhoto"),
          key: "photo",
          width: "100px",
          fixed: "left",
          scopedSlots: { customRender: "photo" }
        },
        {
          title: this.$t("FormFullName"),
          key: "full_name",
          width: "200px",
          fixed: "left",
          scopedSlots: { customRender: "full_name" }
        },
        {
          title: "Slug",
          key: "slug",
          width: "40%",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("JobTitle"),
          key: "position",
          width: "40%",
          scopedSlots: { customRender: "position" }
        },
        {
          title: this.$t("FormBirthDate"),
          key: "birth_date",
          width: "30%",
          scopedSlots: { customRender: "birth_date" }
        },
        {
          title: this.$t("FormNat"),
          key: "nation",
          width: "30%",
          scopedSlots: { customRender: "nation" }
        },
        {
          title: this.$t("FormPhone"),
          key: "phone_number",
          width: "30%",
          scopedSlots: { customRender: "phone_number" }
        },
        {
          title: this.$t("FormInnerNum"),
          key: "inner_number",
          width: "30%",
          scopedSlots: { customRender: "inner_number" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "30%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("Author"),
          key: "author",
          width: "30%",
          scopedSlots: { customRender: "author" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          width: "100px",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      search: "",
      tableProps: {
        results: [],
        count: 1
      },
      title: {
        uz: "Rahbariyat",
        oz: "Раҳбарият",
        ru: "Руководство",
        en: "Management"
      },
      LimitList: [
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        }
      ],
      perPage: 10,
      page: 1,
      cardsIsEmpty: true,
      offset: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
      console.log(this.selectedPosts)
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$store.dispatch("control/fetch", {
          type: 0
        })
        if (r) {
          this.$set(this, "tableProps", {
            results: r || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    SearchInput() {
      this.offset = 0
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const data = await this.$store.dispatch("control/fetch", {
          limit: this.perPage,
          offset: this.offset,
          search: this.search,
          type: 0
        })
        if (data) {
          this.$set(this, "tableProps", {
            results: data.results || [],
            count: data.count
          })
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$store.dispatch(
              "control/removeItemAction",
              i
            )
            if (res && res.status === 204) {
              this.$message.success("Новости Успешно удалено")
            }
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
        await this.fetchData()
      } else {
        try {
          const res = await this.$store.dispatch("control/removeItemAction", id)
          if (res && res.status === 204) {
            this.$message.success("Новости Успешно удалено")
            this.fetchData()
          }
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`/admin/structure/leadership/${node.id}/update/`, {
          order: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    }
  }
}
</script>

<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
</style>
